// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxPTyw0puka92r_HjpyPuz_Wg66z8XuJY",
  authDomain: "adleraugen-da863.firebaseapp.com",
  projectId: "adleraugen-da863",
  storageBucket: "adleraugen-da863.appspot.com",
  messagingSenderId: "970324445053",
  appId: "1:970324445053:web:2081c6070eb96994baae14"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



